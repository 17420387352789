:global(#app) {
  .addButton {
    background: transparent;
    box-shadow: none;
    color: #6b808c;
    font-weight: normal;
    margin-top: 8px;
    padding: 6px 11px;
    text-align: left;
    text-decoration: underline;
    transition: background 0.3s ease;

    &:hover {
      background: #e9e9e9;
    }
  }

  .items {
    margin-top: 8px;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
    }
  }
}
